module.exports = {
    siteUrl: "http://xenox.dev", // Site domain. Do not include a trailing slash!

    postsPerPage: 12, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

    siteTitleMeta: "XenoX Dev", // This allows an alternative site title for meta data for pages.
    siteDescriptionMeta:
        "Simplified articles about CSS, HTML, JavaScript, and all things related to web design, Open Source, and development.", // This allows an alternative site description for meta data for pages.

    shareImageWidth: 1000, // Change to the width of your default share image
    shareImageHeight: 523, // Change to the height of your default share image

    shortTitle: "XenoX", // Used for App manifest e.g. Mobile Home Screen
    siteIcon: "favicon.svg", // Logo in /static dir used for SEO, RSS, and App manifest
    backgroundColor: "#e9e9e9", // Used for Offline Manifest
    themeColor: "#15171A", // Used for Offline Manifest
};
